(function ($) {

    $.fn.vitoValidate = function (options) {
        return this.each(function () {
            var element = $(this);
            // if element already has a single instance, stop early
            if (element.data('vitoValidate')) {
                element.data('vitoValidate').updateOptions(options);
                return;
            }
            var vitoValidate = new VitoValidator(this, options);
            // fill data with init information to prevent double init
            element.data('vitoValidate', vitoValidate);
        });
    };

    var VitoValidator = function (element, options) {

        var $form = $(element),
                $field = null,
                fieldValue = '',
                fieldBoundErrors = 0,
                globalTotalErrors = 0,
                globalSelector = '',
                globalConditionName = '',
                globalError = false,
                utilities = new VitoValidatorUtil();

        // check if options is object, or undefined.
        // also check if element to attach validator to is a form element.
        // if not, break the execution and log new error.
        if (typeof options !== 'object' && typeof options !== 'undefined') {
            printErrorMessage(consoleMessages.WRONG_OPTIONS);
            return false;
            ;
        }
        if (!$form.is('form')) {
            printErrorMessage(consoleMessages.WRONG_ORIGIN);
            return false;
        }

        var consoleMessages = {
            // statuses
            SUCCESSFULLY_ATTACHED: 'Validation is successfully attached to form.',
            VALIDATION_FAIL: 'Validation -> {0} <- failed for field -> {1} <-',
            VALIDATION_SUCCESS: 'Validation -> {0} <- passed for field -> {1} <-',
            // notices
            NONE_SELECTED: 'No element matched selector -> {0} <-',
            // errors
            WRONG_OPTIONS: 'Wrong options format passed.',
            WRONG_ORIGIN: 'Wrong origin for validation. Must be an html form.',
            PARAM_NOT_INTEGER: 'Parameter passed for condition -> {1} <- must be an integer, "{0}" supplied.',
            PARAM_NOT_FUNCTION: 'Parameter -> {0} <- must be a function.',
            UNKNOWN_CONDITION: 'Unknown condition passed.'
        },
        validations = ['required', 'maxLength', 'minLength', 'betweenLength', 'email', 'url', 'number', 'min', 'max', 'between', 'germanDate', 'fileExtension', 'fileSize'];

        var options = $.extend({
            debug: false,
            strict: false,
            rules: [],
            showAlerts: true,
            errorColor: "#F00",
            alertClass: 'error',
            bootstrapAlerts: false,
            bootstrapAlertClass: 'danger',
            // events
            onFail: null,
            onSuccess: null
        }, options || {});

        options.alertClass = utilities.trimDots(options.alertClass);
        // check if onSuccess and onFail are of 'function' type (or null, as default).
        // if not, break the execution and log new error.
        if (!$.isFunction(options.onSuccess) && utilities.toType(options.onSuccess) !== 'null') {
            printErrorMessage(utilities.formatString(consoleMessages.PARAM_NOT_FUNCTION, 'onSuccess'));
            return;
        }
        if (!$.isFunction(options.onFail) && utilities.toType(options.onFail) !== 'null') {
            printErrorMessage(utilities.formatString(consoleMessages.PARAM_NOT_FUNCTION, 'onSuccess'));
            return;
        }

        // default messages for different types of validation tests.
        // can be overriden by passing 'messages' param
        var defaultMessages = {
            'required': 'This field is required.',
            'maxLength': 'This field has a maxLength rule that is not matched.',
            'minLength': 'This field has a minLength rule that is not matched.',
            'betweenLength': 'This field has a betweenLength rule that is not matched.',
            'email': 'This field must have a valid email value.',
            'url': 'This field must have a valid url value.',
            'number': 'This field must have a valid numeric value.',
            'min': '...',
            'max': '...',
            'between': '...',
            'germanDate': 'This field must have a valid date value (German date format, e.g. 26.02.1990).',
            'fileExtension': '...',
            'fileSize': '...',
        };
        // at this point, validator object is attached to the form.
        printDebugMessage(consoleMessages.SUCCESSFULLY_ATTACHED);

        this.updateOptions = function (newOptions) {
            options.rules = newOptions.rules;
        };

        // main validation function
        this.validate = function () {
            globalError = false;
            for (var i = 0; i < options.rules.length; i++) {
                var rule = options.rules[i], conditions = {}, messages = {};
                for (var property in rule) {
                    if (rule.hasOwnProperty(property)) {
                        if (property === 'selector') {
                            globalSelector = rule[property];
                            $field = $(globalSelector);
                            if ($field.length === 0) {
                                printDebugMessage(consoleMessages.NONE_SELECTED + rule[property]);
                                continue;
                            }
                        } else if (property === 'conditions') {
                            conditions = rule[property];
                        } else if (property === 'messages') {
                            messages = rule[property];
                        }
                    }
                }
                performValidation(conditions, messages);
            }
            if (globalError && $.isFunction(options.onFail)) {
                options.onFail();
            } else {
                if ($.isFunction(options.onSuccess)) {
                    options.onSuccess();
                }
            }
            return !globalError;
        };

        // loggers
        function printDebugMessage(message) {
            if (options.debug === true) {
                console.log('Debug message [' + utilities.formattedTime() + ']: ' + message);
            }
        }
        ;
        function printErrorMessage(message) {
            console.error('ERROR [' + utilities.formattedTime() + ']: ' + message);
        }
        ;


        function performValidation(conditions, messages) {
            if ($field.length === 0) {
                return;
            }
            fieldValue = $field.val(),
                    fieldBoundErrors = 0;

            if (options.strict !== true) {
                fieldValue = fieldValue.trim();
            }

            for (var condition in conditions) {
                if (fieldBoundErrors > 0)
                    continue;
                if (conditions.hasOwnProperty(condition)) {
                    var message = defaultMessages[condition];
                    if (messages.hasOwnProperty(condition)) {
                        message = messages[condition];
                    }
                    globalConditionName = condition;
                    switch (condition) {
                        case 'required':
                            vTestRequired(message);
                            break;
                        case 'maxLength':
                            vTestMaxLength(conditions[condition], message);
                            break;
                        case 'minLength':
                            vTestMinLength(conditions[condition], message);
                            break;
                        case 'betweenLength':
                            vTestBetweenLength(conditions[condition], message);
                            break;
                        case 'email':
                            vTestEmail(message);
                            break;
                        case 'url':
                            vTestUrl(message);
                            break;
                        case 'number':
                            vTestNumber(message);
                            break;
                        case 'min':
                            vTestMin(conditions[condition], message);
                            break;
                        case 'max':
                            vTestMax(conditions[condition], message);
                            break;
                        case 'between':
                            //vTestBetween(conditions[condition], message);
                            break;
                        case 'germanDate':
                            vTestGermanDate(message);
                            break;
                        case 'fileExtension':
                            // TBD
                            break;
                        case 'fileSize':
                            // TBD
                            break;
                        default:
                            return true;
                    }
                } else {
                    printErrorMessage(consoleMessages.UNKNOWN_CONDITION);
                    alert("DEV: Serious error occured! Check console for more information on the issue.");
                }
            }
        }
        ;

        // process html alerts
        function vAppendAlert($wrapper, message) {
            fieldBoundErrors++;
            
            
            //dercos hack
            $field.unbind('input change propertychange');
            $field.bind('input propertychange', function () {
                if (this.value.trim().length > 0) {
                    $(this).removeAttr('style');
                }
            });
            $field.bind('change', function () {
                if ($(this).is(":hidden")) {
                    $(this).prev('.button-options').find('.answers a, .button-option a').removeAttr('style');
                    $(this).prev('.dropdown').find('.dropdown-toggle').removeAttr('style');
                }
                if ($(this).is(':checkbox')) {
                    $(this).parent('p, label').removeAttr('style');
                }
            });
            
            
            if (options.showAlerts) {
                if (options.bootstrapAlerts !== true) {
                    if ($wrapper.find('.' + options.alertClass + '.' + globalConditionName).length === 0)
                        $wrapper.append('<p class="' + options.alertClass + ' ' + globalConditionName + '">' + message + '</p>');
                } else {
                    if ($wrapper.find('.alert-' + options.bootstrapAlertClass + '.' + globalConditionName).length === 0)
                        $wrapper.append('<div class=" alert alert-' + options.bootstrapAlertClass + ' ' + globalConditionName + '">' + message + '</div>');
                }
            } else {
                $field.css({
                    'border-width': '2px',
                    'border-style': 'solid',
                    'border-color': options.errorColor
                });
                // dercos hack
                if ($field.is(":hidden")) {
                    $field.prev('.button-options').find('.answers a, .button-option a').css({
                        'border-width': '2px',
                        'border-style': 'solid',
                        'border-color': options.errorColor
                    });
                    $field.prev('.dropdown').find('.dropdown-toggle').css({
                        'border-width': '2px',
                        'border-style': 'solid',
                        'border-color': options.errorColor
                    });
                }
                if ($field.is(':checkbox')) {
                    $field.parent('p, label').css({'color': options.errorColor});
                }
            }
            return false;
        }
        function vTestResetAlerts($wrapper) {
            $wrapper.find('.' + options.alertClass + '.' + globalConditionName, '.alert-' + options.bootstrapAlertClass + '.' + globalConditionName).remove();
            $field.removeAttr('style');
            //dercos hack
            if ($field.is(":hidden")) {
                $field.prev('.button-options').find('.answers a, .button-option a').removeAttr('style');
                $field.prev('.dropdown').find('.dropdown-toggle').removeAttr('style');
            }
            if ($field.is(':checkbox')) {
                $field.parent('p, label').removeAttr('style');
            }
        }


        // validation tests below this point
        function vTestRequired(message) {
            var $wrapper = $field.parent();
            if (($field.is(':checkbox') && $field.is(':checked')) || (!$field.is(':checkbox') && fieldValue !== "")) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestMaxLength(maxLength, message) {
            if (!utilities.isInt(maxLength)) {
                printErrorMessage(
                        utilities.formatString(
                                consoleMessages.PARAM_NOT_INTEGER,
                                maxLength,
                                globalConditionName
                                )
                        );
                alert("DEV: Serious error occured! Check console for more information on the issue.");
                globalError = true;
                return;
            }
            var $wrapper = $field.parent();
            if (fieldValue.length <= maxLength) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestMinLength(minLength, message) {
            if (!utilities.isInt(maxLength)) {
                printErrorMessage(
                        utilities.formatString(
                                consoleMessages.PARAM_NOT_INTEGER,
                                maxLength,
                                globalConditionName
                                )
                        );
                alert("DEV: Serious error occured! Check console for more information on the issue.");
                globalError = true;
                return;
            }
            var $wrapper = $field.parent();
            if (fieldValue.length >= minLength) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestBetweenLength(range, message) {
            var $wrapper = $field.parent();
            if (fieldValue.length >= range[0] && fieldValue.length <= range[1]) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestEmail(message) {
            var $wrapper = $field.parent();
            if (utilities.validateEmail(fieldValue)) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestNumber(message) {
            var $wrapper = $field.parent();
            if (!isNaN(fieldValue)) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestMin(min, message) {
            if (!utilities.isInt(min)) {
                printErrorMessage(
                        utilities.formatString(
                                consoleMessages.PARAM_NOT_INTEGER,
                                min,
                                globalConditionName
                                )
                        );
                alert("DEV: Serious error occured! Check console for more information on the issue.");
                globalError = true;
                return;
            }
            var $wrapper = $field.parent();
            if (fieldValue >= min) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestMax(max, message) {
            if (!utilities.isInt(max)) {
                printErrorMessage(
                        utilities.formatString(
                                consoleMessages.PARAM_NOT_INTEGER,
                                max,
                                globalConditionName
                                )
                        );
                alert("DEV: Serious error occured! Check console for more information on the issue.");
                globalError = true;
                return;
            }
            var $wrapper = $field.parent();
            if (fieldValue <= max) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestUrl(message) {
            var $wrapper = $field.parent();
            if (utilities.isURL(fieldValue)) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }


        function vTestGermanDate(message) {
            var $wrapper = $field.parent();
            if (utilities.isGermanDate(fieldValue)) {
                vTestResetAlerts($wrapper);
                printDebugMessage(
                        utilities.formatString(
                                consoleMessages.VALIDATION_SUCCESS,
                                globalConditionName,
                                globalSelector
                                )
                        );
                globalError = globalError || false;
                return true;
            }
            printDebugMessage(
                    utilities.formatString(
                            consoleMessages.VALIDATION_FAIL,
                            globalConditionName,
                            globalSelector
                            )
                    );
            vAppendAlert($wrapper, message);
            globalError = true;
            globalTotalErrors++;
        }
    };

    var VitoValidatorUtil = function () {

        this.formattedTime = function () {
            var a = new Date();
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
            return time;
        };

        this.trimDots = function (value) {
            return value.replace(/\./gm, '');
        };

        this.isInt = function (value) {
            return !isNaN(value) && (function (x) {
                return (x | 0) === x;
            })(parseFloat(value))
        };

        this.toType = function (obj) {
            return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
        }

        this.formatString = function () {
            var theString = arguments[0];
            for (var i = 1; i < arguments.length; i++) {
                var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
                theString = theString.replace(regEx, arguments[i]);
            }
            return theString;
        };

        this.validateEmail = function (email) {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            return re.test(email);
        };

        this.isURL = function (str) {
            var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
            var url = new RegExp(urlRegex, 'i');
            return str.length < 2083 && url.test(str);
        };

        this.isGermanDate = function (value) {
            var check = false;
            var re = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
            if (re.test(value)) {
                var adata = value.split('.');
                var dd = parseInt(adata[0], 10);
                var mm = parseInt(adata[1], 10);
                var yyyy = parseInt(adata[2], 10);
                var xdata = new Date(yyyy, mm - 1, dd);
                if ((xdata.getFullYear() == yyyy) && (xdata.getMonth() == mm - 1) && (xdata.getDate() == dd))
                    check = true;
                else
                    check = false;
            } else {
                check = false;
            }
            return check;
        };

        this.inArray = function (needle, haystack) {
            var length = haystack.length;
            for (var i = 0; i < length; i++) {
                if (haystack[i] == needle)
                    return true;
            }
            return false;
        }

        this.paramFromUrl = function (name) {
            name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + name + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(window.location.href);
            if (results == null)
                return "";
            else
                return results[1];
        }
    };

}(jQuery));